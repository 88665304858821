<template>
    <form @submit.prevent>
        <div class="container">
            <div v-for="user in users" :key="user">
                <div v-if="user.Id===editId">
            <div class="form-group">
                <h4>{{editId}}</h4>
                <input
                v-model="user.ExpirationDate"
                class="form-control"
                placeholder="ExpirationDate"
                >
                <input
                v-model="user.Login"
                class="form-control"
                placeholder="Login"
                >
                <input
                v-model="user.Password"
                class="form-control"
                placeholder="Password"
                >
                <button class="btn btn-dark" @click="$emit('changeUser',user)"> Accept </button>
            </div>
         </div>
        </div>
    </div>
    </form>
</template>

<script>
export default {
    data(){
        return {
            user: {
                Id: '',
                ExpirationDate: '',
                Login: '',
                Password:''
            }
        }
    },
    props:{
        users:{
            type:Object,
            required: true
        },
        editId:{
            type:Number
        }
    },
    methods: {
        changeUser(){
        },
    },
}
</script>

<style>
</style>