 <template>
    <form @submit.prevent>
        <div class="container">
            <div class="form-group">
                <h4>Creating User</h4>
                <input
                v-model="user.ExpirationDate"
                class="form-control"
                placeholder="ExpirationDate"
                >
                <input
                v-model="user.Login"
                class="form-control"
                placeholder="Login"
                >
                <input
                v-model="user.Password"
                class="form-control"
                placeholder="Password"
                >
                <button class="btn btn-dark" @click="createUser"> Create user </button>
         </div>
        </div>
    </form>
 </template>

 <script>
 export default {
    data(){
        return {
            user: {
                ExpirationDate: '',
                Login: '',
                Password:''
            }
        }
    },
    methods: {
        createUser(){
            this.$emit('create', this.user);
            this.user ={
            ExpirationDate:{
                default: null
            },
            Login:{
                default: null
            },
            Password:{
                default: null
            }
        }
    }
 }
}
</script>

<style>


.form-control{
    margin-top:10px;
}

.btn-dark{
    margin:10px 5px 0 0;
}
</style>