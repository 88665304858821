<template>
    <div class="col-12">
        <div class="post">
            <table class="table table-striped">
                 <thead class="thead-dark">
                     <tr>
                        <th scope="col">ExpirationDate</th>
                        <th scope="col">Login</th>
                        <th scope="col">Id</th>
                        <th scope="col">Настройки</th>
                     </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.ExpirationDate }}</td>
                        <td>{{ user.Login }}</td>
                        <td>{{ user.Id}}</td>
                        <td>
                            <button class="btn btn-dark" @click="$emit('delete',user)">Delete</button>
                            <button class="btn btn-dark" @click="$emit('showEdit',user)">Edit</button>
                        </td>
        </tr>
        </tbody>
    </table>
     </div>
    </div>
</template>

<script>                  

export default {
    components: {},
    props:{
        users:{
            type:Array,
            required: true
        }
    }
    

}
</script>


<style scoped>
.post {

    margin: 5px
}
</style>