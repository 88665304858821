<template>
    <div class="my-modal" v-if="show" @click.stop="hideModal">
        <div class="modal__content" @click.stop >
            <slot></slot>
        </div>
    </div> 
</template>

<script>
    export default {
        name: 'my-modal',
        props:{
            show:{
                type: Boolean,
                default: true
            }
        },
        methods:{
            hideModal(){
                this.$emit('update:show',false)
            }
        }
    }
</script>

<style>
.my-modal{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background:rgba(1,1,1,0.4);
    position: fixed;
    display: flex;
}

.modal__content{
    margin: auto;
    background: white;
    border-radius: 12px;
    padding: 20px;
}
</style>