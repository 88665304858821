<template>
  <div class="container">
    <my-modal v-model:show="editVisible">
      <EditForm :users="users" @changeUser="changeUser" :editId="editId">
      </EditForm>
    </my-modal>
    <h1>User List</h1>
      <div class="row">
        <div class="col-3">
          <UserForm @create="addUser"></UserForm>
        </div>
        <!-- <div class="col-2">
        <RegisterForm/>
        </div> -->
      </div>
    <UserList :users="users" @delete="deleteUser"  @showEdit="showEdit" style="padding-top: 50px;" ></UserList>
  </div>
</template>

<script>
// import RegisterForm from './components/RegisterForm.vue';
import UserList from './components/UserList.vue';
import UserForm from './components/UserForm.vue';
import axios from 'axios';
import MyModal from './components/UI/MyModal.vue';
import EditForm from '@/components/EditForm.vue';

export default {
  name: 'App',
  components: {
    UserForm,
    UserList,
    MyModal,
    EditForm
},
  data(){
    return {
      users: [
      ],
      editVisible: false,
      editId:''
    }
  },
  methods:{
    createUser(user){
      this.users.push(user)
    },
    async fetchUsers() {
      try {
        const response = await axios.get('http://90.188.57.70:6007/draft/TestApi');
        this.users = response.data.Body
      } catch (e){
        alert('alert')
      }
    },
    async deleteUser(user){
      console.log(user.Id)
      await fetch('http://90.188.57.70:6007/draft/TestApi?Id='+ user.Id,{
          method: 'DELETE'
      })
    },
    async changeUser(user){
      console.log(user.Id)
      await fetch('http://90.188.57.70:6007/draft/TestApi?Id='+ user.Id,{
        method: 'PUT',
        body:JSON.stringify({
          ExpirationDate: user.ExpirationDate,
          Login: user.Login,
          Password: user.Password
        })})
      this.editVisible=false;
    },

    showEdit(user){
      this.editId = user.Id
      this.editVisible=true;
    },

    async addUser(user){
      await fetch('http://90.188.57.70:6007/draft/TestApi',{
        method: 'POST',
        body: JSON.stringify({
          ExpirationDate: user.ExpirationDate,
          Login: user.Login,
          Password: user.Password
        })
      })
    }
  },
  mounted() {
    this.fetchUsers();
  }
}
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  
}
</style>
